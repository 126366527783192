$(document).foundation({
    equalizer: {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true
    }
});
$(document).on('click', 'a[href^="#"]', function(event) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});
$(document).ready(function() {
    if ($('.bgimage img').length > 0) {
        $('.theme-detail main > .row').addClass("top");
        processBackgroundImages();
    }

    $('.mobile-trigger').on("click", function(){
        $(this).toggleClass('open');
        $(this).next('ul').toggle();
    })


    /*var video = $('.videoContent video source')[0];
    var videosrc = video.getAttribute("data-src");
    video.setAttribute('src', videosrc);
    video.play();*/




    fontsize();
    initFontSize();
});
$(document).ajaxStop(function() {
    workgroupPopup();
});
window.addEventListener('resize', function() {
    $('#smallmenu').hide();
    $('#hamburger').removeClass('open');
});

var processBackgroundImages = function() {
    $('.bgimage').each(function() {
        var src = $(this).find('img').attr('src');
        if (src) {
            $(this).css('background-image', 'url("' + src + '")');
            if($(this).hasClass('link')){
                $(this).click(function() {
                    window.location.href = $(this).find('a').attr('href');
                })
            }
        }
    });
};

// functies
function openNav() {
    $('#hamburger').toggleClass('open');
    $('#smallmenu').toggle();
}

function workgroupPopup() {
    // modal for workshops
    if ($('#workgroups').length > 0) {
        $('.modal').on("click", function() {
            var text = ($(this).find('.modal-text').html());
            var shown = $('#workgroups .popup').css("display");

            console.log(text);

            if (shown === 'block' && (text)) {
                $('#workgroups .popup .inner').html(text);
            } else if (text) {
                $('#workgroups .popup').fadeToggle(300);
                $('#workgroups .popup .inner').html(text);
            } else {
                $('#workgroups .popup .inner').html("Er is nog geen tekst aanwezig voor deze sessie.");
            }

            // close handler
            $('.close').on("click", function() {
                $('.popup').hide();
            });
        });
    }
}

function fontsize() {
    $('.js-font').on('click', function() {
        event.preventDefault();
        $('body').attr('class', '').addClass($(this).data('size'));
        // re-calculate height of blocks
        $(document).foundation('equalizer', 'reflow');

        // set setting in localstorage, and apply changes directly without refresh
        var current = $('body').attr('class');
        localStorage.setItem("fontSize", current);
    });
}

function initFontSize() {
    switch (localStorage.fontSize) {
        case "small":
            console.log('initfontsize: small font chosen');
            $("body").addClass("small");
            break;

        case "regular":
            console.log('initfontsize: regular font chosen');
            $("body").addClass("regular");
            break;

        case "large":
            console.log('initfontsize: large font chosen');
            $("body").addClass("large");
            break;
    }
}

function contentLoaderMeetings(id, data) {
    // var parentDiv = document.querySelector("#" + id);
    // parentDiv.innerHTML = data;
    // if (data.indexOf('row') > 0){
    //     // meetings exist
    //     parentDiv.parentNode.parentNode.parentNode.parentNode.classList.remove('hide');
    // }
}